<template>

  <v-container id="profileSettings" class="h-100">

    <div class="back-button text-right">
      <v-btn icon @click="goBack">
        <v-icon color="grey darken-2">mdi-chevron-down</v-icon>
      </v-btn>
    </div>

    <div class="pb-3 mb-3 unselectable">
      <div class="top-text my-3" v-if="!isTransporter">
        <h1 class="title is-1 thin black--text">Кого</h1>
        <h1 class="title is-1 black--text">везём?</h1>
      </div>
      <div class="top-text my-3" v-else>
        <h1 class="title is-1 thin black--text">Кто</h1>
        <h1 class="title is-1 black--text">везёт?</h1>
      </div>
    </div>

    <div class="remaining-space">
      <!-- Картинка пользователя -->
      <v-row justify="center">
        <img
          src="/assets/avatar-profile-2.svg"
          alt="Изображение профиля"
          style="width: 60%;"
          class="mt-3"
        >
      </v-row>

      <v-row justify="center" class="mb-3">
        <h1 class="title is-5 mt-3" v-if="isTransporter">Перевозчик</h1>
        <h1 class="title is-5 mt-3" v-else>Пользователь</h1>
      </v-row>

<!--      <v-row v-if="authType == 1 || authType == 2">-->
<!--        <v-col class="mb-0 pb-0">-->
<!--          <div class="buttons justify-end">-->
<!--            <b-button-->
<!--              type="is-text"-->
<!--              icon-left="pencil">-->
<!--              Изменить-->
<!--            </b-button>-->
<!--          </div>-->
<!--        </v-col>-->
<!--      </v-row>-->

      <v-row v-if="authType == 1 || authType == 2">
        <v-col class="pt-0">

          <b-field
            expanded
            class="mb-0">
<!--            prepend-icon="+7"-->
<!--            v-model="profile_details ? ('+7' + profile_details.number) : ''"-->
            <v-text-field
              type="tel"
              label="Телефон"
              append-icon="mdi-phone"
              :value="numberWrapper"
              :readonly="true"

              :error="false"
              error-messages="Телефон не подтверждён"
              :error-messages="authType == 1 ? 'Телефон не подтверждён' : ''"

              outlined>
            </v-text-field>
          </b-field>

          <div class="buttons justify-start" v-if="authType == 1">
            <b-button
              class="w-100"
              size="is-small"
              @click="toConfirmPhone"
              type="is-danger">
              Подтвердить
            </b-button>
          </div>

          <b-field
            expanded
            class="mb-0">
            <v-text-field
              type="text"
              label="Имя"
              v-model="profile_details.name"
              :readonly="true"
              outlined>
            </v-text-field>
          </b-field>

          <b-field
            expanded
            class="mb-0">
            <v-text-field
              type="text"
              label="Фамилия"
              v-model="profile_details.first_name"
              :readonly="true"
              outlined>
            </v-text-field>
          </b-field>

          <b-field
            expanded
            class="mb-0">
            <v-text-field
              type="email"
              label="Почта"
              v-model="profile_details.email"
              append-icon="mdi-email"
              :readonly="true"
              outlined>
            </v-text-field>
          </b-field>

          <div class="buttons justify-start">
            <b-button
              class="w-100"
              size="is-small"
              @click="toLogout"
              type="is-danger">
              Выйти
            </b-button>
          </div>

        </v-col>
      </v-row>

      <v-row v-if="authType == 0">
        <v-col>
          <h3 class="pb-3">
            Вы не авторизованы, сделайте это, чтобы получить доступ к заказам
          </h3>

          <b-button
            type="is-success"
            class="w-100"
            @click="goToReg">
            Зарегистрироваться
          </b-button>
        </v-col>
      </v-row>


      <v-row class="mt-5" v-if="authType == 0">
        <v-form ref="profile_form" v-model="valid" lazy-validation class="w-100">
          <v-card class="w-100 mb-3 auth_card">
            <v-card-text class="pb-0">
              <div class="pa-3 pb-0">
                <h1 class="title is-5 text-center" style="font-size: 1rem;">Войти</h1>

                <b-field
                  expanded
                  class="mb-0">
                  <v-text-field
                    v-model="tel_user"
                    prefix="+7"
                    label="Телефон"
                    v-mask="'##########'"
                    :rules="phoneRules"
                    append-icon="mdi-phone"
                    inputmode="tel"
                    outlined></v-text-field>
                </b-field>

                <b-field
                  expanded
                  class="mb-0">
                  <v-text-field
                    v-model="pass"
                    :rules="passwordRules"

                    :type="showPass ? 'text' : 'password'"
                    :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPass = !showPass"

                    label="Пароль"
                    outlined></v-text-field>
                </b-field>

                <p class="text-center cursor-pointer" @click="goToForgotPass">Забыл пароль</p>
              </div>

            </v-card-text>
            <v-card-actions class="px-7 pt-0 pb-5 justify-space-between">
              <b-button
                type="is-success"
                class="w-100"
                @click="login">
                Войти
              </b-button>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-row>
    </div>

    <p class="text-center about-program" @click="toAboutProgramPage">О программе</p>

  </v-container>

</template>

<script>
export default {
  name: 'ProfilePage',
  data() {
    return {
      clickCount: 0,

      pass: '',
      tel_user: '',
      valid: false,

      showPass: false,
    };
  },
  created() {
  },
  computed: {
    passwordRules() {
      return this.$store.getters.PASSWORD_RULES;
    },
    phoneRules() {
      return this.$store.getters.PHONE_RULES;
    },
    numberWrapper() {
      if (this.profile_details && this.profile_details.number) {
        if (this.profile_details.number[0] == '+') {
          return this.profile_details.number
        }
        return ('+' + this.profile_details.number)
      }
      return ''
    },
    profile_details: {
      get() {
        return this.$store.getters.PROFILE_DETAILS
      },
      set(value) {
        this.$store.commit('SET_PROFILE_DETAILS', value)
      }
    },
    isTransporter() {
      return this.$store.getters.ACCOUNT_TYPE == 1;
    },
    authType() {
      return this.$store.getters.AUTH_TYPE;
    },
  },
  methods: {
    toLogout() {
      this.$store.dispatch('LOGOUT').then(() => {
        this.$router.push('/activity-feed');
      }).catch(() => {
        this.$buefy.toast.open({
          message: 'Произошла ошибка!',
          type: 'is-danger'
        });
      });
    },
    goToReg() {
      this.$router.push('/registration');
      this.$store.commit('SET_REGISTRATION_FROM', 'profile');
    },
    login() {
      if (this.$refs.profile_form.validate()) {
        this.$store.dispatch('LOGIN', {
          number: this.tel_user,
          pwd: this.pass
        }).then(() => {
          this.$buefy.toast.open({
            message: 'Спасибо за вход!',
            type: 'is-success'
          });
        }).catch((error) => {
          if (error === "Password incorrect") {
            this.$buefy.toast.open({
              message: 'Неверный пароль',
              type: 'is-danger'
            });
          } else if (error === "User not found") {
            this.$buefy.toast.open({
              message: 'Пользователя с таким номером нет',
              type: 'is-danger'
            });
          } else {
            this.$buefy.toast.open({
              message: 'Произошла ошибка!',
              type: 'is-danger'
            });
          }
        });
      } else {
        this.$buefy.toast.open({
          message: 'Заполните корректно поля',
          type: 'is-danger'
        });
      }
    },
    goToForgotPass() {
      if (this.tel_user == '') {
        this.$buefy.toast.open({
          message: 'Введите номер телефона',
          type: 'is-danger'
        });
        return
      }

      // this.$store.commit('SET_REGISTRATION_FROM', 'forgotPass');
      this.$store.commit('SET_PHONE_FOR_FORGOT_PASSWORD', this.tel_user);

      this.$store.dispatch('FORGOT_PASSWORD', this.tel_user).then(() => {
        this.$buefy.toast.open({
          message: 'Код подтверждения отправлен',
          type: 'is-success'
        });

        this.$router.push('/forgot-password');
      }).catch(() => {
        this.$buefy.toast.open({
          message: 'Ошибка! Попробуйте позже',
          type: 'is-danger'
        });
      });
    },
    toConfirmPhone() {
      this.$router.push('/verification-code');
    },
    toAboutProgramPage() {
      this.$router.push('/about-program');
    },
    goBack() {
      this.$router.back();
    }
  },
};
</script>

<style scoped>
#profileSettings .v-icon.notranslate.material-icons {
  font-style: normal !important;
  margin-right: 10px !important;
}

.avatar {
  width: 80% !important;
  height: auto !important;
}

.title.is-1 {
  font-size: 4.5rem !important;
}

.title.is-1.thin {
  font-weight: 300;
}

#profileSettings.container {
  display: flex;
  flex-direction: column;
}

.remaining-space {
  flex-grow: 1;
}

.auth_card .title {
  color: #c54932;
}

#profileSettings .button.is-success {
  background-color: #ff6347 !important;
}

#profileSettings .auth_card {
  background-color: #ffeeec;
}

.about-program {
  font-weight: 400px;
  font-size: 400;
  margin-top: 20px;
  margin-bottom: 0px;
  cursor: pointer;
}

</style>
